<template>
  <div class="Phidrofacial">
    <Hero :heading-text="'PHIDROFACIAL &amp; PHIBRIGHT'" :heading-subtitle="'TRETMANI KOJI HIDRIRAJU I DUBINSKI HRANE VAŠU KOŽU.'" :cta-text="'ZAKAŽITE TERMIN'" background-url="hidro.jpg"></Hero>
    <HeadlineCopy src1="PHIDROFACIAL-machine.jpg" :heading-text="'Phidrofacial'" :heading-subtitle="'Dubinski, neinvazivni tretman.<br> Čišćenje lica se može obaviti za 30-60 minuta.<br/>Ovim tretmanom dobijamo trenutne, primetne rezultate bez iritacije <br/>uklanjamo mrtve ćelije kože i nečistoće.<br/>Ujedno hranimo kožu bogatim hidratantnim serumima.'" :cta-text="'Zakažite termin'"></HeadlineCopy>
    <SingleTextComponent :heading-text="'HIDRATACIJA - HIDRO FACIAL - PHIBRIGHT'" background-url="PhiBright.jpg"></SingleTextComponent>
    <HeadlineCopy src1="phibrightserum.jpg" :heading-text="'Phibright'" :heading-subtitle="'Microneedling je vrlo jednostavna, sigurna, efikasna i minimalno invazivna terapijska tehnika. Koristi se za širok spektar indikacija, uključujući bore, dehidriranu kožu, masnu i problematičnu kožu, hiperpigmentacije, ožiljke, proširene pore, ožiljke od akni, strije, alopecije. Princip rada ove tehnike zasnovan je na činjenici da stvaranje mikrokanala izaziva kontrolisanu povredu kože uz minimalno oštećenje epidermisa. Koža je perforirana mikro iglama sa minimalnom invazivnošću i bolom, a u površinskom sloju kože stvaraju se nevidljivi mikrokanali kao transportni putevi unutar kože. Phi Bright je tretman sa serumima koji se putem aplikacije pripremaju i dobijamo personalizovane koktele, prema stanju i potrebama lica kao i prema uslovima i navikama.'"></HeadlineCopy>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import HeadlineCopy from '@/components/HeadlineCopy.vue'
import SingleTextComponent from '@/components/SingleTextComponent.vue'
import Footer from '@/components/Footer.vue'



export default {
  name: 'Phodrofacial',
  components: {
    Hero,
    HeadlineCopy,
    SingleTextComponent,
    Footer
  }
}
</script>